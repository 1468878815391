
.color-footer{
    background:var(--lightBlue) !important;
}

  .special-input {
    border: 2px solid #3498db;
    border-radius: 8px;
    padding: 10px;
    transition: border-color 0.3s, box-shadow 0.3s;
  }

  .special-input:focus {
    border-color: #e74c3c;
    box-shadow: 0 0 8px rgba(231, 76, 60, 0.6);
  }
  .btn-block {
    width: fit-content; /* Adjust the button width based on content */
  }