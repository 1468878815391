.c-container{
    justify-content: space-between;

    }
    .c-container>div{
        flex: 1;
        }
     .c-right{
        width: 100%;
        margin-left: 60px;
     }   
     .c-left{
        gap: 0.5rem;
        margin-left: 60px;

     }   
     .contact-mode{
        margin-top: 2rem;
        gap: 1rem;
     }
     .ContactModes .row{
        gap: 1.5rem;
     }
     .mode{
        width: 16rem;
        padding: 1rem;
        border: 0.8px solid rgba(128, 128, 128, 0.143);
        border-radius: 5px;
        gap: 1rem;
        transition: all 300ms ease-in;
     }
     .mode .button{
        width: 100%;
        background: var(--lightblue);
        color: var(--blue);
        font-size: 0.9rem;
     }
     .node>:nth-child(1){
    
        width: 100%;
        gap: 1.6rem;
     }
     .mode .detail .primaryText{
        font-weight: 600;
        font-size:1.1rem;
        margin-left: 10px;
     }
     .mode:hover{
        scale: 1.1rem;
        box-shadow: var(--shadow);
    
     }
     .mode .button:hover{
        background: var(--blue-gradient);
        color:white !important;
    
     }
     .button a:hover {
      background: var(--blue-gradient);
      color: white !important;
      transform: scale(0.8);
    }
    
     .image-container{
        width: 30rem;
        height: 35rem;
        overflow: hidden;
        border-radius: 15rem 15rem 0 0;
        border: 8px solid rgba(255, 255, 255, 0.12);

    }
    .image-container>img{
        width: 100%;
        height: 100%;
    }
    
     @media (max-width:1024px){
       .c-container{
      flex-direction: column;
       }
       .c-right{
         justify-content: center;
         margin-left: 10px;
      }   
      .c-left{
         
         margin-left: 10px;
 
      }   
       .ContactModes{
          width: 100%;
       }
       .image-container{
        width: 95%;
        height: 25rem;
       }
       .row{
          flex-direction: column;
          width: 100%;
       }
       .mode{
          width: 100%;
       }
    }