
.heading-aboutus{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-size: 45px;
    font-weight: bold;
    
}
.h1-aboutus{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
    color: orange;

}
.p1-aboutus{
    margin-left: 40px;
    margin-right: 40px;
    font-size: 20px;
    margin-top: 20px;
}
.h1-sub-aboutus{
    display: flex;
    justify-content: left;
    align-items: left;
    margin-top: 20px;
    font-size: 25px;
    font-weight: bold;
    margin-left: 40px;

}
.p2-aboutus{
    margin-left: 40px;
    margin-right: 40px;
    font-size: 25px;
    margin-top: 20px;
}
.span-heading{
    font-size: 20px;
    font-weight: bold;
}
.h1-sub-aboutus-sub{
    margin-left: 100px;
    margin-right: 40px;
    font-size: 20px;
    margin-top: 20px;
}
.span-bold{
    font-weight: bold;
}
.image1-about img{
    width: 100%;

    height: 250px;

    
}
.image2-about img{
    width: 70%;

    height: 250px;
}
.image3-about img{
    width: 70%;

    height: 250px;

}
.image4-about img{
    width: 70%;
    height: 250px;
}

.image-container-about{
    display: flex;
    text-align: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    margin-bottom: 50px;
}


/* Aboutus.css */

/* ... existing styles ... */

.heading-aboutus,
.h1-aboutus,
.p1-aboutus,
.image-container-about{
  opacity: 0;
  animation: fadeInAnimation 1s ease forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Aboutus.css */

/* ... Other styles ... */

.styled-image {
    max-width: 100%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    /* Add any other styling you want */
  }
  .styled-image:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  /* Aboutus.css */

/* ... existing styles ... */

/* Adjust the heading size for smaller screens */
@media screen and (max-width: 768px) {
    .heading-aboutus {
      font-size: 30px;
    }
  }
  
  /* Adjust the font size for smaller screens */
  @media screen and (max-width: 768px) {
    .h1-aboutus,
    .p1-aboutus,
    .h1-sub-aboutus,
    .p2-aboutus,
    .h1-sub-aboutus-sub {
      font-size: 18px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  
  /* Adjust the image size for smaller screens */
  @media screen and (max-width: 768px) {
    .image1-about img,
    .image2-about img,
    .image3-about img,
    .image4-about img {
      height: 200px;
    }
  }
  
  /* Aboutus.css */
  
  /* ... Other styles ... */
  
  /* Make images responsive */
  .styled-image {
    max-width: 100%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    /* Add any other styling you want */
  }
  
  .styled-image:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Aboutus.css */

/* ... Other styles ... */

/* Make images responsive */
.styled-image {
    max-width: 100%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    /* Add any other styling you want */
  }
  
  .styled-image:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Ensure images have some spacing on smaller screens */
  @media screen and (max-width: 768px) {
    .image-container-about {
      flex-direction: column;
      align-items: center;
    }
    
    .styled-image {
      max-width: 80%;
      margin: 10px 0;
    }
  }
  