
.Header-projComp{
    margin-top:20px;

    display:flex;
    justify-content:center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    

}
.underline_orange{
  color: orange;
  padding-Right:10px
}
.imag-fitting img{
    width: 90%;
    height: 350px;
    border-top-left-radius:200px ;
    border-top-right-radius:200px ;
    margin-top: -10px;
    border: 5px solid grey;
}
.project-text{
margin-bottom: 13px;
color: gray;

}
.heading-bold-project{
    font-size: 20px;
    font-weight: bold;
    color: orange;
}
.margintop-row{
    margin-top: 120px;
    margin-left: 20px;
    text-align: start;

}
.col-mad-center{

   padding-bottom: 30px;
}
.project-text {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  
  .heading-bold-project {
    font-weight: bold;
  }
  
  /* If you want to align text to the start */
  .project-text {
    text-align: start;
  }

  /* Projectcompleted.css */

/* ... your existing styles ... */

/* Responsive layout adjustments */
@media screen and (max-width: 768px) {
  .col-md-6 {
    flex-basis: 100%;
    margin-bottom: 40px;
  }

  .imag-fitting img {
    max-width: 100%;
    height: auto;
  }

  .project-text {
    font-size: 16px;
  }

  /* Additional responsive adjustments as needed */
}

/* Add more media queries for larger screens if needed */

/* ... your existing styles ... */
/* Projectcompleted.css */

/* ... your existing styles ... */

/* Responsive layout adjustments for iPad */
@media screen and (max-width: 1024px) {
  .col-md-6 {
    flex-basis: 100%;
    margin-bottom: 40px;
  }

  .imag-fitting img {
    max-width: 100%;
    height: auto;
  }

  .project-text {
    font-size: 16px;
  }

  /* Additional responsive adjustments as needed */
}

/* Responsive layout adjustments for Android devices */
@media screen and (max-width: 768px) {
  .col-md-6 {
    flex-basis: 100%;
    margin-bottom: 40px;
  }

  .imag-fitting img {
    max-width: 100%;
    height: auto;
  }

  .project-text {
    font-size: 16px;
  }

  /* Additional responsive adjustments as needed */
}

/* ... your existing styles ... */
