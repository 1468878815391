.f-container{
    justify-content: space-between;
}
.bg-color-footer{
    background-color: black;
}
.f-left{
    gap:1rem;
    margin-left: 30px;
}
.f-right{
    margin-right: 30px;
}
.f-menu{
    gap: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 500;
}
/* Aboutus.css */

.icon-rp{
    display: flex;
    align-items: center;
  }
  
  .icon-text {
    margin-left: 10px; /* Add some spacing between the icon and the text */
  }
  
  .ic0n-span {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .ic0n-span2 {
    font-size: 14px;
    color: grey;
  }
  .gradient-icon {
    width: 25px; /* Set the width and height to match the icon size */
    height: 25px;
    background: linear-gradient(to right, red, blue, green, orange);
    display: inline-flex; /* Make sure the container doesn't affect layout */
    align-items: center;
    justify-content: center;
  }
  
  
  
@media (max-width:768px){
.f-container{
    justify-content: center;
}
.f-container>div{
    align-items: center !important;
    text-align: center;
}
.f-left{
 
    margin-left: 0px;
}
.f-right{
    margin-right: 0px;
}
 }