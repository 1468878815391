.App{
  position: relative;
  overflow-x: clip;
}
.App>:nth-child(1){
  background-color: black !important;
  position: relative;
}
.color-bg{
  background-color: black;

}