/* Custom CSS for the BootstrapCarousel component */

/* Shared styles for images and captions */
.carousel-image {
    max-height: 530px;
    border-radius: 10px;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
  }
  
  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.5s ease-in-out;
    width: 70%; /* Adjust the width for better readability */
    text-align: center;
  }
  
  /* Styling for captions */
  .carousel-caption h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .carousel-caption p {
    font-size: 16px;
  }
  
  /* Indicator styles */
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    background-color: orange;
    border: 1px solid red;
    border-radius: 50%;
    transition: background-color 0.5s ease-in-out;
  }
  
  .carousel-indicators .active {
    background-color: orange;
  }
  
  /* Control styles */
  